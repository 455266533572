<template>
  <div id="Eastern_Account_Number">
    <top></top>
    <!-- 主体表格 -->
    <div class="Baggage_Change_Order_body_div">
      <!-- 导出数据 -->
      <div class="vue-json-excel" v-if="Terminal_type == 'pc'">
        <el-switch
          style="display: block; margin-right: 10px"
          v-model="value2"
          active-color="#13ce66"
          inactive-color="skyblue"
          @change ='xiugai'
          active-text="开启自动"
          inactive-text="开启人工"
        >
        </el-switch>
        <el-button
          style="margin-right: 10px"
          type="success"
          @click="upload_click"
          >账号上传</el-button
        >
        <input
          v-show="false"
          ref="upload"
          type="file"
          accept=".xls,.xlsx"
          @change="readExcel($event)"
        />
        <template>
          <download-excel
            class="export-excel-wrapper"
            :data="res"
            :fields="json_fields"
            header="东航行李额收益报表"
            name="东航行李额收益报表.xls"
          >
            <el-button type="success">数据导出</el-button>
          </download-excel>
        </template>
      </div>

      <div
        class="Baggage_Change_Order_body"
        :style="'height:' + table_height + 'px'"
      >
        <table class="Baggage_Change_Order_table" border="1">
          <tr>
            <td v-if="Terminal_type == 'pc'">出卡人</td>
            <td>账号</td>
            <td>密码</td>
            <td>钱包密码</td>
            <td v-if="Terminal_type == 'pc'">可用里程数</td>
            <td v-if="Terminal_type == 'pc'">剩余里程数</td>
            <td v-if="Terminal_type == 'pc'">已经使用了的里程数</td>
            <td v-if="Terminal_type == 'pc'">销售金额</td>
            <td v-if="Terminal_type == 'pc'">成本</td>
            <td v-if="Terminal_type == 'pc'">添加时间</td>
            <td v-if="Terminal_type == 'pc'">里程更新时间</td>
          </tr>
          <tr
            v-for="(item, index) in res"
            :key="index"
            @click="mobile(item, index)"
          >
            <td v-if="Terminal_type == 'pc'">
              <span>{{ item.name }}</span>
            </td>
            <td>{{ item.account }}</td>
            <td>{{ item.password }}</td>
            <td>{{ item.wallet_pwd }}</td>
            <td v-if="Terminal_type == 'pc'">{{ item.available_mileage }}</td>
            <td v-if="Terminal_type == 'pc'">{{ item.Remaining_mileage }}</td>
            <td v-if="Terminal_type == 'pc'">{{ item.use_mileage }}</td>
            <td v-if="Terminal_type == 'pc'">{{ item.price }}</td>
            <td v-if="Terminal_type == 'pc'">{{ item.cost }}</td>
            <td v-if="Terminal_type == 'pc'">{{ item.created_time }}</td>
            <td v-if="Terminal_type == 'pc'">{{ item.mileage_time }}</td>
          </tr>
        </table>
      </div>
      <!-- mobile 独有-->
      <van-popup
        v-if="Terminal_type == 'mobile'"
        v-model="show"
        round
        position="bottom"
      >
        <div class="Baggage_Change_Order_popup_div">
          <div class="Baggage_Change_Order_popup_flex">
            <p>出卡人</p>
            <p>
              <span>{{ popup_item.name }},</span>
            </p>
          </div>
          <div class="Baggage_Change_Order_popup_flex">
            <p>账号：</p>
            <p>{{ popup_item.account }}</p>
          </div>
          <div class="Baggage_Change_Order_popup_flex">
            <p>密码：</p>
            <p>{{ popup_item.password }}</p>
          </div>
          <div class="Baggage_Change_Order_popup_flex">
            <p>钱包密码：</p>
            <p>{{ popup_item.wallet_pwd }}</p>
          </div>
          <div class="Baggage_Change_Order_popup_flex">
            <p>可用里程数：</p>
            <p>{{ popup_item.available_mileage }}</p>
          </div>
          <div class="Baggage_Change_Order_popup_flex">
            <p>剩余里程数：</p>
            <p>{{ popup_item.Remaining_mileage }}</p>
          </div>
          <div class="Baggage_Change_Order_popup_flex">
            <p>已经使用了的里程数：</p>
            <p>{{ popup_item.use_mileage }}</p>
          </div>
          <div class="Baggage_Change_Order_popup_flex">
            <p>销售金额：</p>
            <p>{{ popup_item.price }}</p>
          </div>
          <div class="Baggage_Change_Order_popup_flex">
            <p>成本：</p>
            <p>{{ popup_item.cost }}</p>
          </div>
          <div class="Baggage_Change_Order_popup_flex">
            <p>添加时间：</p>
            <p>{{ popup_item.created_time }}</p>
          </div>
          <div class="Baggage_Change_Order_popup_flex">
            <p>里程更新时间：</p>
            <p>{{ popup_item.mileage_time }}</p>
          </div>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import top from "../components/BodyComponents.vue";
import { read, utils } from "xlsx";
export default {
  name: "Eastern_Account_Number",
  data() {
    return {
      outputs: [],
      table_height: sessionStorage.getItem("table_height"),
      res: [],
      json_fields: {
        姓名: "passenger_name",
        票号: "ticket_no",
        时间: "create_time",
        额度: "baggage_allowance",
        原本支出里程: "mileage",
        实际支出里程: "actual_mileage",
        东航卡信息: "account_information",
      },
      res_index: 0,
      popup_item: [],
      show: false,
      value2: '',
      Eastern_admin: [],
    };
  },
  components: {
    top,
  },
  mounted() {
    // 东航开关
    this.kaiguan()
    // 东航账户查询
    this.$https("selection/ren/find_dong", "", "post").then((res) => {
      this.res = res.data.data;
    });
  },
  methods: {
    xiugai(){
      this.$https("selection/ren/set", "", "post").then((res) => {
      this.kaiguan()
    });
    },
    kaiguan(){
      this.$https("selection/ren/get", "", "post").then((res) => {
      res.data.data == 0 ? this.value2 = false : true
    });
    },
    mobile(item, index) {
      if (this.Terminal_type == "pc") return false;
      this.show = true;
      this.popup_item = item;
      this.res_index = index;
    },
    upload_click() {
      this.$refs.upload.dispatchEvent(new MouseEvent("click"));
    },
    readExcel(e) {
      const files = e.target.files;
      // 如果没有文件名
      if (files.length <= 0) {
        return false;
      } else if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        this.$message.error("上传格式不正确, 请上传xls或者xlsx格式");
        return false;
      }

      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result;
          // 切换为新的调用方式
          const workbook = read(data, {
            type: "binary",
          });
          // 取第一张表
          const wsname = workbook.SheetNames[0];
          // 切换为新的调用方式 生成json表格内容
          const ws = utils.sheet_to_json(workbook.Sheets[wsname]);
          //   数据处理
          for (let i in ws) {
            // console.log(ws[i]['出卡人'])
            if (
              (ws[i]["出卡人"] ?? "") == "" ||
              (ws[i]["账号"] ?? "") == "" ||
              (ws[i]["密码"] ?? "") == "" ||
              (ws[i]["支付密码"] ?? "") == ""
            ) {
              this.$message.error("表格格式不符合要求");
              return false;
            }
            // 转整
            ws[i]["卡内里程"] = Number(ws[i]["卡内里程"]);
            ws[i]["可用"] = Number(ws[i]["可用"]);
            // 构建传递数组
            this.Eastern_admin.push({
              name: ws[i]["出卡人"],
              kanei_licheng: ws[i]["卡内里程"],
              keyong: ws[i]["可用"],
              admin: ws[i]["账号"],
              pwd: ws[i]["密码"],
              pay_pwd: ws[i]["支付密码"],
              chengben: ws[i]["成本"],
              shenyu_licheng: ws[i]["还剩里程"],
              xiaoshou: ws[i]["销售"],
              yijingshiyong: ws[i]["已经使用"],
            });
          }
          //   console.log(this.Eastern_admin);
          this.$https(
            "selection/ren/add_dong",
            "json=" + JSON.stringify(this.Eastern_admin),
            "post"
          ).then((res) => {});
        } catch (e) {
          return false;
        }
      };
      fileReader.readAsBinaryString(files[0]);
    },
  },
};
</script>



<style scoped>
</style>